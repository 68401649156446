import * as React from 'react';
import * as styles from './article-card.module.scss';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-react-intl';

interface IProps {
  article: {
    headline: string;
    overview: string;
    content: string;
    image: IGatsbyImageData;
    path: string;
  };
  imageLoading: GatsbyImageProps['loading'];
}
export const ArticleCard = ({ article, imageLoading }: IProps) => {
  const intl = useIntl();

  return (
    <div className={styles.card}>
      <Link to={article.path} className="d-flex flex-column flex-md-row">
        <GatsbyImage
          className={styles.image}
          image={article.image}
          loading={imageLoading}
          alt={intl.formatMessage({
            id: article.headline,
          })}
        />
        <div className="d-flex flex-column flex-grow-1">
          <h2>
            {intl.formatMessage({
              id: article.headline,
            })}
          </h2>
          <p>
            <FormattedMessage id={article.overview} />
          </p>
          <span>
            <FormattedMessage id="general.details_link" />
          </span>
        </div>
      </Link>
    </div>
  );
};
