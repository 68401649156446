import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { ROUTES } from '../../../../const/routes';
import { Routes } from '../../../../enum/Routes';
import { ArticleCard } from '../article-card/article-card';

export const ArticlesList = () => {
  const articlesTranslationKey = 'blog.articles_data';

  const graphqlData = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          absolutePath: { regex: "/blog/" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: TRACED_SVG
                width: 200
              )
            }
          }
        }
      }
    }
  `);

  const images = useMemo(() => {
    return graphqlData.images.edges.reduce(
      (prev: any, { node: { name, childImageSharp } }: any) => ({
        ...prev,
        [name]: childImageSharp.gatsbyImageData,
      }),
      {}
    );
  }, [graphqlData]);

  const articles = [
    {
      headline: `${articlesTranslationKey}.interesting_facts.headline`,
      overview: `${articlesTranslationKey}.interesting_facts.overview`,
      content: `${articlesTranslationKey}.interesting_facts.content`,
      image: images['interesting-facts'],
      path: ROUTES[Routes.BLOG_POST](
        'top-interesting-facts-about-cardboard-boxes'
      ),
    },
  ];

  return (
    <>
      {articles.map((article, index) => (
        <ArticleCard
          key={article.headline}
          article={article}
          imageLoading={index === 0 ? 'eager' : 'lazy'}
        />
      ))}
    </>
  );
};
