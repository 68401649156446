import * as React from 'react';
import { PageProps } from 'gatsby';
import { MainTemplate } from '../../templates/main/main';
import { SEO } from '../../components/seo/seo';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { PageHeadline } from '../../components/page-headline/page-headline';
import { ArticlesList } from '../../ui/pages/blog/articles-list/articles-list';

const BlogPage = ({ location }: PageProps) => {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'blog.seo_title' })}
        description={intl.formatMessage({ id: 'blog.seo_description' })}
      />
      <div className="container">
        <PageHeadline>
          <FormattedMessage id="blog.headline" />
        </PageHeadline>
        <ArticlesList />
      </div>
    </MainTemplate>
  );
};

export default BlogPage;
